import type { ComponentType, ReactElement } from "react"

export function AutoCopyrightStatement(
    Component: ComponentType<any>
): ComponentType {
    return (props: { children?: ReactElement }) => {
        const textProps = props.children?.props?.children?.props
        if (textProps && typeof textProps.children == "string") {
            textProps.children = textProps.children.replace(
                "YYYY",
                new Date().getFullYear().toString()
            )
        }
        return <Component {...props} />
    }
}
